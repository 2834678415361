import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import ModalWrapper from "./ModalWrapper";
import { Input, Option } from "@material-tailwind/react";
import { DaumProps } from "../Interface/KeywordsProps";
import { useEffect, useState } from "react";
import KeywordsAPI from "../../services/API/clients/KeywordsAPI";
import { toast } from "react-toastify";
import CategoriesAPI from "../../services/API/clients/CategoriesAPI";
import Select from 'react-select';
import { PlaceCustomStyle } from "../../pages/Users/CardEdit";

export default function ModalKeywords({ open, setOpen, data, handleGetData }: Readonly<{ open: boolean; setOpen: () => void, data: DaumProps, handleGetData: Function }>) {
    const { t } = useTranslation();
    const [categories, setCategories] = useState<{ value: string; label: string }[]>([]);
    const [datas, setDatas] = useState<{ category: String, enumValue: string; frLabel: string; enLabel: string }>({
        category: "",
        enumValue: "",
        frLabel: "",
        enLabel: ""
    });

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await CategoriesAPI.getAll();
            if (response.status === 200) {
                const categories = response.body.map((category: {enumValue: string, frLabel: string, enLabel: string}) => {
                    return {
                        value: category.enumValue,
                        label: category.frLabel
                    }
                })
                setCategories(categories);
            }
        }
        fetchCategories();
    }, []);

    useEffect(() => {
        setDatas({
            enumValue: data.customLabel.toUpperCase(),
            frLabel: data.customLabel,
            enLabel: data.customLabel,
            category: data?.parent?.category?.enumValue ?? ""
        });
    }, [data]);

    const handleSubmited = async () => {
        if (datas.enumValue === "" || datas.frLabel === "" || datas.enLabel === "" || datas.category === "") {
            toast.warning(t("fillAllFields"));
            return;
        }
        const dataSend = {
            enumValue: datas.enumValue,
            categories: [
               `/api/categories/${datas.category}`
            ],
            frLabel: datas.frLabel,
            enLabel: datas.enLabel
        }
        const response = await KeywordsAPI.addKeyword(dataSend);
        if (response.status === 201) {
            toast.success(t("keywordAdded"));
            handleGetData();
            setOpen();
        } else if (response.status === 409) {
            toast.warning(t("keywordExist"));
        }
    }

    const handleChange = (selectedOption: any) => {
        setDatas({ ...datas, category: selectedOption.value });
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium text-gray-900 text-center mb-4">
                    {t("addKeyword", { keyword: data?.parent?.category?.frLabel})}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="w-[400px] relative flex flex-col gap-3 mx-auto">
                    <Select
                        options={categories} 
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                        styles={PlaceCustomStyle}
                        className="w-full relative top-0 bg-white border-orange-500"
                        placeholder={t("category")}
                        onChange={handleChange}
                        value={datas?.category && categories.find((option) => datas?.category === option.value)}
                        noOptionsMessage={() => t("noResult")}
                    />
                    <Input
                        crossOrigin=""
                        type="text"
                        color="orange"
                        value={datas.enumValue}
                        onChange={(e) => e && setDatas({ ...datas, enumValue: e.target.value })}
                        label={t("enumValue")}
                    />
                    <Input
                        crossOrigin=""
                        type="text"
                        color="orange"
                        value={datas.frLabel}
                        onChange={(e) => e && setDatas({ ...datas, frLabel: e.target.value })}
                        label={t("frId")}
                    />
                    <Input
                        crossOrigin=""
                        type="text"
                        color="orange"
                        value={datas.enLabel}
                        onChange={(e) => e && setDatas({ ...datas, enLabel: e.target.value })}
                        label={t("enId")}
                    />
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleSubmited()}
                    >
                        {t("confirm")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
