export const HeadersPictures = [
    {
        id: "id",
        col: "id"
    },
    {
        id: "picture",
        col: "minPath"
    },
    {
        id: "owner",
        col: "owner"
    },
    {
        id: "exifData",
        col: "exifData"
    },
    {
        id: "uploadedAt",
        col: "uploadedAt"
    },
    {
        id: "publishedAt",
        col: "publishedAt"
    },
    {
        id: "title",
        col: "title"
    },
    {
        id: "description",
        col: "description"
    },
    {
        id: "keywords",
        col: "keywords"
    },
    {
        id: "category",
        col: "category"
    },
    {
        id: "typeStyle",
        col: "typeStyle"
    },
    {
        id: "watermark",
        col: "watermark"
    },
    {
        id: "watermarkType",
        col: "watermarkType"
    },
    {
        id: "nsfw",
        col: "nsfw"
    },
    {
        id: "identifiablePeople",
        col: "identifiablePeople"
    },
    {
        id: "identifiableProperties",
        col: "identifiableProperties"
    },
    {
        id: "writtenContract",
        col: "writtenContract"
    },
    {
        id: "license",
        col: "license"
    },
    {
        id: "pictureOriginal",
        col: "picture"
    },
    {
        id: "contract",
        col: "contract.id"
    },
    {
        id: "galleries",
        col: "galleries?.parent?.title"
    },
    {
        id: "favoriteCount",
        col: "favoriteCount"
    },
    {
        id: "viewCount",
        col: "viewCount"
    },
    {
        id: "writtenDocuments",
        col: "writtenDocuments"
    },
    {
        id: "canBeSold",
        col: "canBeSold"
    },
    {
        id: "canBeBought",
        col: "canBeBought"
    },
    {
        id: "validationStatus",
        col: "validationStatus"
    }
]