export const HeaderCardDetail = [
    {
        id: "company",
        col: "company",
    },
    {
        id: "firstname",
        col: "firstname",
    },
    {
        id: "lastname",
        col: "lastname",
    },
    {
        id: "pseudo",
        col: "portfolio?.name",
    },
    {
        id: "email",
        col: "email",
    },
    {
        id: "phone",
        col: "phone",
    },
    {
        id: "birthdate",
        col: "birthdate",
    },
    {
        id: "country",
        col: "country"
    },
    {
        id: "address",
        col: "place"
    },
    {
        id: "iban",
        col: "iban",
    },
    {
        id: "bic",
        col: "bic"
    }
];