import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Input, Option, Select, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PicturesAPI from "../../services/API/clients/PicturesAPI";
import TablePicturesResearch from "../../components/Tables/TablePicturesResearch";
import { HeadersPictures } from "./HeadersPictures";
import { Daum } from "../../components/Interface/PictureResearch";

export default function Picture() {
    const { t } = useTranslation();
    const [nbrItemsPerPage, setNbrItemsPerPage] = useState<string>("20");
    const [query, setQuery] = useState<string>("");
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [pictures, setPictures] = useState<{
        pagination: { current: number, last: number, totalItems: number, parPage: number},
        data: Daum[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });

    const getList = async () => {
        const response = await PicturesAPI.pictures(nbrItemsPerPage);
        if (response.status === 200) {
            setPictures(response.body);
            if (response.body.pagination.totalItems > 0) {
                setIsInitialized(true);
            }
        }
    }

    useEffect(() => {
        getList()
    }, [])

    const callPictures = async (page: number) => {
        const response = await PicturesAPI.picturesPagination(nbrItemsPerPage, page, query);
        if (response.status === 200) {
            setPictures(response.body);
        }
    }

    const handleResearchWithQuery = async () => {
        const response = await PicturesAPI.pictures(nbrItemsPerPage, query);
        if (response.status === 200) {
            setPictures(response.body);
        }
    }

    useEffect(() => {
        callPictures(1);
    }, [nbrItemsPerPage])

    return (
        <>
            {isInitialized ? (
                <div className="w-full px-4 md:px-8 mx-auto flex flex-col gap-4">
                    <div className="flex flex-row items-center justify-between w-full gap-4">
                        <Input crossOrigin="" type="text" color="orange" className="bg-white" label={t("search")} value={query} onChange={(e) => setQuery(e.target.value)} />
                        <IconButton placeholder="" color="orange" onClick={() => handleResearchWithQuery()}>
                            <FontAwesomeIcon icon="paper-plane" />
                        </IconButton>
                        <div className="w-52">
                            <Select
                                label={t("printNumber")}
                                color="orange"
                                onChange={(e) => setNbrItemsPerPage(e ?? "0")}
                                placeholder=""
                                className="bg-white"
                                value={nbrItemsPerPage}
                            >   
                                <Option value="10">{t("ten")}</Option>
                                <Option value="20">{t("twenty")}</Option>
                                <Option value="50">{t("fifty")}</Option>
                                <Option value="100">{t("hundred")}</Option>
                                <Option value="200">{t("twoHundred")}</Option>
                            </Select>
                        </div>
                    </div>
                    {pictures?.pagination.totalItems > 0 ? (
                        <TablePicturesResearch header={HeadersPictures} data={pictures?.data} itemsPerPage={pictures?.pagination} callPictures={callPictures} />
                    ) : (
                        <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                            <FontAwesomeIcon icon="images" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                            <Typography placeholder="" className="text-base text-gray-700 mb-4">{t("noPicturesResearch")}</Typography>
                        </div>
                    )}
                </div>

            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <FontAwesomeIcon icon="images" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                    <Typography placeholder="" className="text-base text-gray-700 mb-4">{t("noPictures")}</Typography>
                </div>
            )}
        </>
    )
}