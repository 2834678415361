import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/NavBar/Header";
import { useTranslation } from "react-i18next";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";
import GradientButton from "../../components/Form/Button/GradientButton";
import React, { useEffect } from "react";
import NewsletterAPI from "../../services/API/clients/NewsletterAPI";
import { Headers } from "./Header";
import Table from "../../components/Tables/Table";
import exportFromJSON from "export-from-json";
import { toast } from "react-toastify";
import { Typography } from "@material-tailwind/react";
import { confirmAlert } from "react-confirm-alert";

export default function Newsletter() {
    const { t } = useTranslation();
    const [list, setList] = React.useState<any[]>([]);
    document.title = `ezoom | ${t("newsletter")}`;

    const getList = async () => {
        const response = await NewsletterAPI.news();

        setList(response.body);
    }

    useEffect(() => {
        getList()
    }, [])

    const exportMyFile = () => {
        const fileName = 'list-newsletter'
        const exportType = 'csv'
        exportFromJSON({ data: list, fileName, exportType })
    }

    const deleteMyUser = async (id: string) => {
        if (!id) return;
        
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                        <FontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={onClose} />
                        <Typography placeholder="" className="text-center w-full text-2xl">{t("confirmAction")}</Typography>
                        <div className="w-full flex mt-4 justify-center">
                            <div className="w-full flex flex-col px-2 items-center justify-center">
                                <Typography placeholder="" className="text-center w-full mb-4 text-lg">
                                    {t("confirmDeleteNewsletter")}
                                </Typography>
                                <div className="flex gap-4 items-center justify-center">
                                    <button
                                        className="rounded-full border border-orange-500 hover:border-orange-200 bg-orange-500 hover:bg-orange-200 text-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans my-2"
                                        onClick={async () => {
                                            const response =  await NewsletterAPI.unSubscribe(id)
                                            if (response.status === 200 || response.status === 204) {
                                                getList()
                                                toast.success(t("successDelete"))
                                            } else {
                                                toast.error(t("errorDeleteUser"));
                                            }
                                            getList()
                                            onClose();
                                        }}
                                    >
                                        {t("yes")}
                                    </button>
                                    <button
                                        className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans my-2"
                                        onClick={() => onClose()}
                                    >
                                        {t("no")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    };

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage><FontAwesomeIcon icon="envelope" className="text-orange-500" /> {t("newsletter")}</TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full px-4 mx-auto md:px-8 flex flex-col gap-4">
                        <div className="w-fit ml-2 md:ml-0">
                            <GradientButton text="getCSV" onChanging={exportMyFile}  />
                        </div>
                        <div className="w-full overflow-x-auto">
                            <Table header={Headers} data={list} itemsPerPage={10} onClickAction={deleteMyUser} />
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
