import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardBody, IconButton, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Header from "../../components/NavBar/Header";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import SubscriptionsAPI from "../../services/API/clients/SubscriptionsAPI";
import { HeaderDetails } from "./HeaderDetails";
import { DEFAULT_DATA_SUBSCRIPTION, SubscriptionProps } from "../../components/Interface/SubscriptionProps";

export default function SubscriptionDetail() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [subscription, setSubscription] = useState<{
        id: string;
        subscriptionId: string;
        owner: any;
        plan: string;
        status: string;
        period: string;
        createdAt: string;
    }>({
        id: "",
        subscriptionId: "",
        owner: {
            id: ""
        },
        plan: "",
        status: "",
        period: "",
        createdAt: "",
    });
    const [subs, setSubs] = useState<SubscriptionProps>(DEFAULT_DATA_SUBSCRIPTION);

    const fetchData = async () => {
        if (!id) return;
        
    };

    const fetchSubs = async () => {
        if (!id) return;
        const response = await SubscriptionsAPI.subscriptionTransactions(id);
        if (response.status === 200) {
            setSubs(response.body);
            const resp = await SubscriptionsAPI.subscription(response.body.subscription.id);
            if (resp.status === 200) {
                setSubscription(resp.body);
            }
        }
    };

    useEffect(() => {
        fetchData();
        fetchSubs();
    }, [id]);

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="user-plus" className="text-orange-500" /> {t("subscription")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full mx-auto px-4 md:px-8 flex flex-col gap-4 md:gap-0 md:flex-row flex-wrap">
                        <div className="w-full md:w-96 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-center">
                                <table className="h-full w-full table-auto text-left">
                                    {HeaderDetails.map((data) => {
                                        return (
                                            <tr key={data.id}>
                                                <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                                    <Typography
                                                        placeholder=""
                                                        variant="small"
                                                        color="blue-gray"
                                                        className="font-normal leading-none opacity-70 rounded-tl"
                                                    >
                                                        {t(data.id)}
                                                    </Typography>
                                                </th>
                                                <td className="p-4 border-b border-blue-gray-100/80">
                                                    <Typography placeholder="" variant="small" color="blue-gray" className="font-normal opacity-60">
                                                        {(data.id === "owner" && <a href={`/user/${subscription.owner?.id}`}><IconButton placeholder="" color="orange"><FontAwesomeIcon icon="user" /></IconButton></a>)}
                                                        {(data.id === "createdAt" && moment(subscription[data.id as keyof typeof subscription]).format("DD.MM.YYYY HH:mm"))}
                                                        {(data.id !== "owner" && data.id !== "createdAt" && subscription[data.col as keyof typeof subscription] !== ""
                                                            && t(subscription[data.col as keyof typeof subscription])) ?? "/"
                                                        }
                                                    </Typography>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </table>
                            </Card>
                        </div>
                        <div className="w-full md:w-[calc(100%-24rem)] md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("histotransactions")}
                                    </Typography>
                                    <div className=" max-h-[360px] mt-4 overflow-y-auto">
                                        <div className="flex flex-col gap-2">
                                            <Card placeholder="" key={subs.id} className="border">
                                                <CardBody placeholder="">
                                                    <Typography placeholder="" variant="h5" color="blue-gray" className="mb-2">
                                                        {subs.renewal ? (
                                                            <>{t("renew")}{" "}</>
                                                        ) :
                                                            <>{subs.displayText} - {subs.paymentMethodName}{" "}</>
                                                        }
                                                        <FontAwesomeIcon
                                                            icon={subs.status === "AUTHORIZED" ? "check" : "x"}
                                                            className={subs.status === "AUTHORIZED" ? "text-green-500" : "text-red-500"}
                                                        />
                                                    </Typography>
                                                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                        {moment(subs.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                                                    </Typography>
                                                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                        {t(subs.status)} - <b>{subs.amount}</b> {t("chf")}
                                                    </Typography>
                                                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                        <a className="underline" href={`/transaction/subscription/${subs.id}`}>{subs.id}</a>
                                                    </Typography>
                                                </CardBody>
                                            </Card>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
