import { useEffect, useState } from "react";
import ModalAddGestion from "../../../components/Modals/ModalAddGestion";
import { Button, Input } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import WrapperCards from "../../../components/Cards/WrapperCards";
import TypesAPI from "../../../services/API/clients/TypesAPI";
import { toast } from "react-toastify";

export default function Types() {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [query, setQuery] = useState<string>("");
    const [data, setData] = useState<
    { constEnumValue: string, enumValue: string; frLabel: string; enLabel: string }[]
    >([]);
    const [dataFiltered, setDataFiltered] = useState<
    { constEnumValue: string, enumValue: string; frLabel: string; enLabel: string }[]
    >([]);
    const [dataEdit, setDataEdit] = useState<{
        constEnumValue: string;
        enumValue: string;
        frLabel: string;
        enLabel: string;
    }>({ constEnumValue: "", enumValue: "", frLabel: "", enLabel: "" });
    const [currentEdit, setCurrentEdit] = useState<number>(-1);

    const fetchData = async () => {
        const response = await TypesAPI.getAll();
        if (response.status === 200) {
            setData(
                response.body.map((item: { enumValue: string, frLabel: string, enLabel: string }) => ({
                    constEnumValue: item.enumValue,
                    ...item
                }))
            );
            setDataFiltered(
                response.body.map((item: { enumValue: string, frLabel: string, enLabel: string }) => ({
                    constEnumValue: item.enumValue,
                    ...item
                }))
            );
        }
    };

    const onDelete = async (enumValue: string) => {
        const response = await TypesAPI.deleteType(enumValue);
        if (response.status === 204) {
            fetchData();
            setCurrentEdit(-1);
            setDataEdit({ constEnumValue: "", enumValue: "", frLabel: "", enLabel: "" });
            toast.success(t("typeDeleted"));
        } else if (response.status === 409) {
            toast.warning(t("typeUsed"));
        } else {
            toast.warning(t("typeUsed"));
            // toast.error(t("error"));
        }
    };

    const onSave = async () => {
        const datas = {
            enumValue: dataEdit.enumValue,
            frLabel: dataEdit.frLabel,
            enLabel: dataEdit.enLabel,
        };

        const response = await TypesAPI.updateType(dataEdit.constEnumValue, datas);
        if (response.status === 200) {
            fetchData();
            setCurrentEdit(-1);
            setDataEdit({ constEnumValue: "", enumValue: "", frLabel: "", enLabel: "" });
            toast.success(t("typeUpdated"));
        } else {
            toast.error(t("error"));
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            <ModalAddGestion
                open={open}
                setOpen={() => setOpen(false)}
                fetchData={fetchData}
                type="types"
            />
            <Button
                placeholder=""
                onClick={() => setOpen(true)}
                className="mb-4"
            >
                {t("typesAdd")}
            </Button>

            <Input
                crossOrigin=""
                type="text"
                color="orange"
                label={t("search")}
                value={query}
                onChange={(e) => {
                    setQuery(e.target.value);
                    setDataFiltered(
                        data.filter((cat) =>
                            cat.enumValue
                                .toLowerCase()
                                .includes(e.target.value.toLowerCase())
                        )
                    );
                }}
            />

            <WrapperCards
                dataFiltered={dataFiltered}
                currentEdit={currentEdit}
                setCurrentEdit={setCurrentEdit}
                dataEdit={dataEdit}
                setDataEdit={setDataEdit}
                onSave={onSave}
                onDelete={onDelete}
            />
        </>
    );
}
