import { IconButton, Typography } from "@material-tailwind/react";
import React, { useEffect } from "react";

import Pagination from "./Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DefaultPictureEzoom from "../../assets/img/default-profile.png";
import ContractsAPI from "../../services/API/clients/ContractsAPI";
import { Validation } from "../Interface/PictureResearch";
import ModalRefusePicture from "../Modals/ModalRefusePicture";
import ModalVisuOriginalPath from "../Modals/ModalVisuOriginalPath";
import PicturesAPI from "../../services/API/clients/PicturesAPI";

interface Props {
    nbrItemsPerPage: string;
    header: { col: string; id: string }[];
    data: Validation[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: (page: number) => void;
}

const TablePicturesValidations: React.FC<Props> = ({ nbrItemsPerPage, header, data, itemsPerPage, callPictures }) => {
    const { t } = useTranslation();
    const [openRefuse, setOpenRefuse] = React.useState<boolean>(false);
    const [constantData, setConstantData] = React.useState<boolean[]>([]);
    const [currentId, setCurrentId] = React.useState<string>("");

    const downloadPicture = async (id: string) => {
        const response = await PicturesAPI.downloadPicture(id);
        if (response.status === 200) {
            const link = document.createElement("a");
            link.href = response.body.originalPath;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
        } else {
            toast.error("Error");
        }
    }

    const ImageReturning = ({ element }: {element: Validation }) => {
        return (
            <div className="flex items-center overflow-hidden rounded-md" style={{ width: "75px", height: "75px" }}>
                <img alt="placeholder" className="rounded-md h-full w-full object-cover object-center" src={element?.picture?.publicPath ?? DefaultPictureEzoom} />
            </div>
        );
    };

    const ImageReturningButton = ({ keys }: { keys: number }) => {
        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap text-center"}>
                <IconButton
                    placeholder=""
                    color="orange"
                    onClick={() => {
                        downloadPicture(data?.[keys]?.picture?.id ?? "");
                    }}
                >
                    <FontAwesomeIcon icon="eye" />
                </IconButton>
            </Typography>
        );
    };

    const openMyFile = async (id: string) => {
        const response = await ContractsAPI.getContractPdf(id);
        if (response.status === 200) {
            const url = window.URL.createObjectURL(response.body);
            const link = document.createElement("a");
            link.href = url;
            link.target = "_blank";
            link.rel = "noopener noreferrer";
            link.click();
        } else {
            toast.error(t("errorFile"));
        }
    };

    useEffect(() => {
        if (!openRefuse) {
            setCurrentId("");
            callPictures(itemsPerPage.current);
        }
    }, [openRefuse]);

    return (
        <div className="table-container">
            <ModalRefusePicture nbrItemsPerPage={nbrItemsPerPage} open={openRefuse} setOpen={() => setOpenRefuse(false)} currentId={currentId} />
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            {header?.map((column: { col: string; id: string }, index: number) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        placeholder=""
                                        variant="small"
                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                            index === header.length - 1 ? "text-center" : "text-left"
                                        }`}
                                    >
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((element, key: number) => {
                            const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                            const handleOpenVisuOrga = () => {
                                const oldConstantData = constantData;
                                oldConstantData[key] = !oldConstantData[key];
                                setConstantData([...oldConstantData]);
                            };

                            return (
                                <>
                                    <ModalVisuOriginalPath
                                        open={constantData?.[key] ?? false} 
                                        setOpen={handleOpenVisuOrga} 
                                        picture={element} 
                                    />
                                    <tr key={element.id} className={`example-element-row`}>
                                        {header?.map((column: { id: string; col: string }) => {
                                            return (
                                                <td key={column.id} className={className}>
                                                    {column.id === "picture" && <ImageReturning element={element} />}
                                                    {column.id === "pictureOriginal" && <ImageReturningButton keys={key} />}
                                                    {column.id === "id" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.id}</Typography>}
                                                    {column.id === "reviewer" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.reviewer.fullname}</Typography>}
                                                    {column.id === "reason" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{t(element.reason)}</Typography>}
                                                    {column.id === "action" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{t(element.action)}</Typography>}
                                                    {column.id === "reviewedAt" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{moment(element.reviewedAt).format("DD/MM/YYYY")}</Typography>}
                                                    {(column.id === "contract") && (
                                                        <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap text-center"}>
                                                            {element?.picture?.contractId ? (
                                                                <IconButton
                                                                    placeholder=""
                                                                    color="orange"
                                                                    onClick={() => {
                                                                        openMyFile(element?.picture?.contractId ?? "");
                                                                    }}
                                                                >
                                                                    <FontAwesomeIcon icon="file" />
                                                                </IconButton>
                                                            ) : (
                                                                "/"
                                                            )}
                                                        </Typography>
                                                    )}
                                                    {column.id === "picture[id]" && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.picture.id}</Typography>}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callPictures}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

export default TablePicturesValidations;