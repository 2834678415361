import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionBody, AccordionHeader, Avatar, Button, Checkbox, Typography } from "@material-tailwind/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Sale, WithdrawUser } from "../../components/Interface/Withdraw";
import WithdrawAPI from "../../services/API/clients/WithdrawAPI";
import { HeadersWithdraw } from "./Headers";

export default function Withdraw({ fetchData, datas } : Readonly<{ fetchData: () => void, datas: WithdrawUser[]}> ) {
    const { t } = useTranslation();
    const [open, setOpen] = useState<number>(0);
    const [selectedId, setSelectedId] = useState<string[]>([]);

    useEffect(() => {
        fetchData();
    }, []);

    const returnTypo = (col: string, el: Sale) => {
        switch (col) {
            case "id":
                return el.id;
            case "createdAt":
                return moment(el.createdAt).format("DD.MM.YYYY HH:mm");
            case "saleId":
                return el.saleId;
            case "netWorth":
                return el.netWorth;
            case "orderStatus":
                return el.orderStatus;
            case "contracts":
                return <div className="flex items-center -space-x-4">{el.contracts.map((contract) => <Avatar placeholder="" key={contract.id} variant="circular" alt="user 1" className="border-2 border-white" src={contract.picture.minPath} />)}</div>
            default:
                return "";
        }
    }

    const extractXml = async () => {
        const data = {
            bulk: selectedId.map((id) => (
                    "/api/sales/" + id
            ))
        }
        const response = await WithdrawAPI.newWithdraw(data);
        if (response.status === 201) {
            const blob = new Blob([response.body.document], { type: "text/xml" });
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.download = "document.xml";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
            fetchData();
            setSelectedId([]);
        }
    }

    const markAsPaid = async () => {
        const data = {
            bulk: selectedId.map((id) => (
                "/api/sales/" + id
            ))
        }
        const response = await WithdrawAPI.withdraw(data)
        if (response.status === 200) {
            fetchData();
            setSelectedId([]);
        }
    }

    return (
        <>
            {datas?.length > 0 ? (
                <div className="w-full px-4 md:px-8 mx-auto flex flex-col gap-4">
                    <div className="flex gap-4">
                        <Button
                            placeholder=""
                            color="orange"
                            onClick={() => {
                                extractXml()
                            }}
                        >
                            {t("extractXml")}
                        </Button>
                        <Button
                            placeholder=""
                            color="purple"
                            onClick={() => {
                                markAsPaid()
                            }}
                        >
                            {t("markAsPaid")}
                        </Button>
                    </div>
                    {datas?.map((element, index) => (
                        <Accordion placeholder="" key={element.owner.id} open={open === index}>
                            <AccordionHeader placeholder="" onClick={() => setOpen(index)} className="relative">
                                {element.owner.company && element.owner.company !== "" && element.owner.company + " - "}{element.owner.fullname}
                                <FontAwesomeIcon icon={open === index ? "chevron-up" : "chevron-down"} className="absolute right-0 top-1/2 transform -translate-y-1/2" />
                            </AccordionHeader>
                            <AccordionBody className="overflow-x-auto">
                                <table className="w-full table-auto overflow-x-scroll">
                                    <thead>
                                        <tr>
                                            <th className="border-b border-blue-gray-50 py-3 px-5 text-center"></th>
                                            {HeadersWithdraw?.map((column: { col: string; id: string }) => (
                                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                                    <Typography
                                                        placeholder=""
                                                        variant="small"
                                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                                            index === HeadersWithdraw.length - 1 ? "text-center" : "text-left"
                                                        }`}
                                                    >
                                                        {t(column.id)}
                                                    </Typography>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {element?.sales.map((el, key: number) => {
                                            const className = `py-1 px-5 w-fit ${key === element.sales.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                            return (
                                                <tr key={el.id} className={`example-element-row`}>
                                                    <td className={`py-5 border-b flex flex-row`} style={{ textAlign: "center" }}>
                                                        <Checkbox
                                                            crossOrigin=""
                                                            color="orange"
                                                            checked={selectedId.includes(el.id)}
                                                            onChange={() => {
                                                                if (selectedId.includes(el.id)) {
                                                                    setSelectedId(selectedId.filter((id) => id !== el.id));
                                                                } else {
                                                                    setSelectedId([...selectedId, el.id]);
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                    {HeadersWithdraw?.map((column: { id: string; col: string }) => {
                                                        return (
                                                            <td key={column.id} className={className}>
                                                                <Typography
                                                                    placeholder=""
                                                                    variant="small"
                                                                    className={`text-[11px] text-blue-gray-400 whitespace-nowrap ${
                                                                        key === HeadersWithdraw.length - 1 ? "text-center" : "text-left"
                                                                    }`}
                                                                >
                                                                    {returnTypo(column.col, el)}
                                                                </Typography>
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </AccordionBody>
                        </Accordion>
                    ))}
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                    <FontAwesomeIcon icon="dollar" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                    <Typography placeholder="" className="text-base text-gray-700 mb-4">{t("noPaiement")}</Typography>
                </div>
            )}
        </>
    )
}