export const Headers = [
    {
        id: "picture",
        col: "picture.publicPath"
    },
    {
        id: "picture[id]",
        col: "picture[id]"
    },
    {
        id: "ID",
        col: "id"
    },
    {
        id: "owner",
        col: "owner"
    },
    {
        id: "pictureOriginal",
        col: "picture"
    },
    {
        id: "contract",
        col: "contractId"
    },
    {
        id: "rightsAccords",
        col: "writtenDocuments"
    },
    {
        id: "Validations",
        col: "validations"
    }
]