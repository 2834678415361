import APIClient from "../APIClients";

export default class ContactAPI {
    public static async support() {
        return APIClient.ezoom.get(`/contact-supports`);
    }

    public static async getSupport(id: string) {
        return APIClient.ezoom.get(`/contact-supports${id !== "" ? `/${id}`: ""}`);
    }

    public static async pictureReport() {
        return APIClient.ezoom.get(`/picture-reports`);
    }

    public static async getPictureReport(id: string) {
        return APIClient.ezoom.get(`/picture-reports${id !== "" ? `/${id}`: ""}`);
    }

    public static async galleryReport() {
        return APIClient.ezoom.get(`/gallery-reports`);
    }

    public static async getGalleryReport(id: string) {
        return APIClient.ezoom.get(`/gallery-reports${id !== "" ? `/${id}`: ""}`);
    }

    public static async portfolioReport() {
        return APIClient.ezoom.get(`/portfolio-reports`);
    }

    public static async getPortfolioReport(id: string) {
        return APIClient.ezoom.get(`/portfolio-reports${id !== "" ? `/${id}`: ""}`);
    }
}