import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class CountryAPI {
    public static async place() {
        if (!token)
            return APIClient.noInterceptor.get(`/public/countries`);
        else
            return APIClient.ezoom.get(`/public/countries`);
    }

    public static async newPlace(sessionId: string, data: {}) {
        if (!token)
            return APIClient.noInterceptor.post(`/public/places?session_id=${sessionId}`, data);
        else
            return APIClient.ezoom.post(`/public/places?session_id=${sessionId}`, data);
    }
}
