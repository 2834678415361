import {
    faCreativeCommons,
    faCreativeCommonsBy,
    faCreativeCommonsNc,
    faCreativeCommonsNd,
    faCreativeCommonsSa,
    faCreativeCommonsZero,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { Checkbox, Input, Option, Select, Textarea } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PicturesAPI from "../../services/API/clients/PicturesAPI";
import { Daum } from "../Interface/PictureResearch";
import ModalWrapper from "./ModalWrapper";


export default function ModalEditPicture({ open, setOpen, currentId, datas }: Readonly<{ open: boolean; setOpen: () => void, currentId: string, datas: Daum | null }>) {
    const { t } = useTranslation();
    const [datasToEdit, setDatasToEdit] = useState<{
        title: string,
        description: string,
        rights: string,
        nsfw: boolean,
        sale: string,
    }>({
        title: "",
        description: "",
        rights: "",
        nsfw: false,
        sale: "all-rights-reserved",
    });

    const handleChangeLicence = (e: any) => {
        setDatasToEdit({ ...datasToEdit, rights: e });
    }

    useEffect(() => {
        console.log(datas)
        setDatasToEdit({
            title: datas?.title ?? "",
            description: datas?.description ?? "",
            rights: datas?.license ?? "",
            nsfw: datas?.nsfw ?? false,
            sale: datas?.license ?? "",
        });
    }, [datas]);

    const handleSubmited = async () => {
        const data = {
            ...(datasToEdit.title !== "" && { title: datasToEdit.title }),
            ...(datasToEdit.description !== "" && { description: datasToEdit.description }),
            ...(datasToEdit.rights !== "" && { license: datasToEdit.rights }),
            nsfw: datasToEdit.nsfw,
        }
        const response = await PicturesAPI.putPicture(datas?.id ?? "", data);
        if (response.status === 200) {
            setOpen();
        }
    }

    const licenses = [
        { value: "all-rights-reserved", label: t("all-rights-reserved") },
        { value: "own-license", label: t("own-license") },
        { value: "cc-by", label: t("cc-by_full"), icons: [faCreativeCommons, faCreativeCommonsBy] },
        { value: "cc-by-sa", label: t("cc-by-sa_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsSa] },
        { value: "cc-by-nc", label: t("cc-by-nc_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc] },
        { value: "cc-by-nc-sa", label: t("cc-by-nc-sa_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsSa] },
        { value: "cc-by-nd", label: t("cc-by-nd_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNd] },
        { value: "cc-by-nc-nd", label: t("cc-by-nc-nd_full"), icons: [faCreativeCommons, faCreativeCommonsBy, faCreativeCommonsNc, faCreativeCommonsNd] },
        { value: "cc0", label: t("cc0_full"), icons: [faCreativeCommons, faCreativeCommonsZero] },
    ];

    const handleEditSale = () => {
        if (datasToEdit.sale === "own-license") {
            setDatasToEdit({ ...datasToEdit, sale: "all-rights-reserved", rights: "all-rights-reserved" });
        } else {
            setDatasToEdit({ ...datasToEdit, sale: "own-license", rights: "own-license" });
        }
    }

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center mb-4">
                    {t("editPicture")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="w-[400px] relative flex flex-col gap-3">
                    <Input
                        crossOrigin=""
                        label={t("title")}
                        placeholder=""
                        value={datasToEdit.title}
                        onChange={(e) => setDatasToEdit({ ...datasToEdit, title: e.target.value })}
                        color="orange"
                        className="bg-white"
                    />
                    <Textarea
                        label={t("description")}
                        placeholder=""
                        value={datasToEdit.description}
                        onChange={(e) => setDatasToEdit({ ...datasToEdit, description: e.target.value })}
                        color="orange"
                        className="bg-white"
                    />
                    <Select
                        placeholder=""
                        className="bg-white w-full"
                        color="orange"
                        label={t("license")}
                        value={datasToEdit.rights}
                        onChange={handleChangeLicence}
                        containerProps={{
                            className: "w-full max-h-[100vw]",
                        }}
                        menuProps={{
                            className: "w-full max-h-[100vw]",
                        }}
                        id="licenceSelect"
                    >
                        {licenses.map((license) => (
                            <Option key={license.value} value={license.value}>
                                <div className="flex justify-between flex-row">
                                    <span className="whitespace-nowrap mr-auto">
                                        {license.label}
                                    </span>
                                    {license.icons && (
                                        <span className="float-right flex flex-row gap-1 text-lg">
                                            {license.icons.map((icon, index) => (
                                                <FontAwesomeIcon key={index} icon={icon} />
                                            ))}
                                        </span>
                                    )}
                                </div>
                            </Option>
                        ))}
                    </Select>
                    <Checkbox
                        crossOrigin=""
                        placeholder=""
                        color="orange"
                        checked={datasToEdit.nsfw}
                        onChange={(e) => setDatasToEdit({ ...datasToEdit, nsfw: e.target.checked })}
                        label={datasToEdit.nsfw ? t("nsfwOn") : t("nsfwOff")}
                        id="nsfw"
                    />
                    <Checkbox
                        crossOrigin=""
                        placeholder=""
                        color="orange"
                        checked={datasToEdit.sale === "own-license"}
                        onChange={(e) => handleEditSale()}
                        label={datasToEdit.sale ? t("saleOn") : t("saleOff")}
                        disabled={(datas?.contract?.id && datas?.canBeSold) ? false : true}
                        id="sale"
                    />
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleSubmited()}
                    >
                        {t("confirm")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
