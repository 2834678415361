import { IconButton, Typography } from "@material-tailwind/react";
import React from "react";

import Pagination from "./Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
    header: { col: string; id: string }[];
    data: {
        id: string;
        totalPrice: number;
        orderId: string;
        status: string;
        createdAt: string;
        contracts: {
            id: string;
            picture: {
                id: string;
                width?: number;
                height?: number;
                minPath?: string;
            };
        }[];
    }[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: (page: number) => void;
}

const TableTransactions: React.FC<Props> = ({ header, data, itemsPerPage, callPictures }) => {
    const { t } = useTranslation();

    const ImageReturning = ({ element }: { element: any }) => {
        return (
            <a href={`/order/${element?.owner?.id}`} className="flex items-center overflow-hidden rounded-md my-2" style={{ width: "75px", height: "75px" }}>
                <IconButton placeholder="" color="orange">
                    <FontAwesomeIcon icon="user" />
                </IconButton>
            </a>
        );
    };

    const IdReturning = ({ element }: { element: string }) => {
        const id = element;
        return (
            <a href={`/order/${element}`} className="flex items-center rounded-md my-2 underline">
                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                    {`${id}`}
                </Typography>
            </a>
        );
    };

    const TypoReturning = ({
        element,
        column,
    }: {
        element: {
            id: string;
            totalPrice: number;
            orderId: string;
            status: string;
            createdAt: string;
            contracts: {
                id: string;
                picture: {
                    id: string;
                    width?: number;
                    height?: number;
                    minPath?: string;
                };
            }[];
        };
        column: { id: string; col: string };
    }) => {
        let content = element[column.col as keyof typeof element] ?? "/";
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }
        if (column.id === "createdAt") {
            content = moment(content).format("DD.MM.YYYY");
        }

        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {t(content.toString())}
            </Typography>
        );
    };

    return (
        <div className="table-container">
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            {header?.map((column: { col: string; id: string }, index: number) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        placeholder=""
                                        variant="small"
                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                            index === header.length - 1 ? "text-center" : "text-left"
                                        }`}
                                    >
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map(
                            (
                                element: {
                                    id: string;
                                    totalPrice: number;
                                    orderId: string;
                                    status: string;
                                    createdAt: string;
                                    contracts: {
                                        id: string;
                                        picture: {
                                            id: string;
                                            width?: number;
                                            height?: number;
                                            minPath?: string;
                                        };
                                    }[];
                                },
                                key: number
                            ) => {
                                const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                return (
                                    <tr key={element.id} className={`example-element-row`}>
                                        {header?.map((column: { id: string; col: string }) => {
                                            return (
                                                <td key={column.id} className={className}>
                                                    {column.id === "id" && <IdReturning element={element.id} />}
                                                    {column.id === "owner" && <ImageReturning element={element} />}
                                                    {column.id === "contracts" && (
                                                        <Typography
                                                            placeholder=""
                                                            variant="small"
                                                            color="blue-gray"
                                                            className={"text-gray-500 whitespace-nowrap"}
                                                        >
                                                            {element?.contracts?.length}
                                                        </Typography>
                                                    )}
                                                    {column.id !== "contracts" && column.id !== "owner" && column.id !== "id" && (
                                                        <TypoReturning element={element} column={column} />
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            }
                        )}
                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callPictures}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

export default TableTransactions;
