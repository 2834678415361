import { Typography } from "@material-tailwind/react";
import React from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";
import DefaultPictureEzoom from "../../assets/img/default-banner.png";
import Pagination from "./Pagination";

interface Props {
    header: any;
    data: any;
    itemsPerPage: number;
}

const URL_REDIRECT = process.env.REACT_APP_URL_REDIRECT

const TableReports: React.FC<Props> = ({ header, data, itemsPerPage }) => {
    const  { t } = useTranslation();
    const [currentPage, setCurrentPage] = React.useState(1);
    const totalItems = data.length;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    const visibleItems = data.slice(startIndex, endIndex + 1);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <>
            <div className="table-container max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            {header.map((column: any) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {visibleItems !== undefined &&
                            visibleItems.map((element: any, key: number) => {
                                const className = `py-3 px-5 ${key === visibleItems.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                return newFunction(element, header, className, t);
                            })}
                    </tbody>
                </table>
                <Pagination
                    handlePageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    startIndex={startIndex}
                    endIndex={endIndex}
                />
            </div>
        </>
    );
};

export default TableReports;
function newFunction(element: any, header: any, className: string, t: any) {
    
    return (
        <React.Fragment key={element.id}>
            <tr
                className={`example-element-row ${element.deletedAt !== "1970-01-01T00:00:00.000Z" && element.deletedAt !== undefined && "bg-gray-200"}`}
            >
                {header.map((column: any, i: number) => {
                    return (
                        <td key={column.id} className={className}>
                            <Typography
                                placeholder=""
                                variant="small"
                                color="blue-gray"
                                className={
                                    "text-gray-500 max-w-[600px]"
                                }
                            >
                                {column.id === "ID" 
                                    ? t(element.resource.id) 
                                    : column.id === "picture" 
                                        ? (
                                            <div className="flex items-center overflow-hidden rounded-md" style={{ width: "75px", height: "75px" }}>
                                                <img alt="placeholder" className="rounded-md h-full w-full object-cover object-center" src={element?.resource?.minPath ?? DefaultPictureEzoom} />
                                            </div>
                                        )
                                        : column.id === "date"
                                            ? moment(element.createdAt).format("DD.MM.YYYY")
                                            : column.id === "portfolio" 
                                                ? <a href={`${URL_REDIRECT}portfolio/${element[column.col].split("/")[4]}/photos`} target="_blank">{element[column.col]}</a>
                                                : column.id === "gallery" 
                                                    ? <a href={`${URL_REDIRECT}gallery/${element[column.col].split("/")[4]}`} target="_blank">{element[column.col]}</a>
                                                    : t(element[column.col])}

                            </Typography>
                        </td>
                    );
                })}
            </tr>
        </React.Fragment>
    );
}
