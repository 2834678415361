import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Header from "../../components/NavBar/Header";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";
import { Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import Gestion from "./Gestion";
import Setter from "./Setter";

export default function Settings() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("settings")}`;

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage><FontAwesomeIcon icon="gears" className="text-orange-500" /> {t("settings")}</TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                        <div className="w-full px-4 mx-auto md:px-8 flex flex-col gap-4">
                        <Tabs value="x">
                            <TabsHeader placeholder="">
                                <Tab placeholder="" value="x">
                                    {t("gestion")}
                                </Tab>
                                <Tab placeholder="" value="y">
                                    {t("set")}
                                </Tab>
                            </TabsHeader>
                            <TabsBody placeholder="">
                                <TabPanel value="x">
                                    <Gestion />
                                </TabPanel>
                                <TabPanel value="y">
                                    <Setter />
                                </TabPanel>
                            </TabsBody>
                        </Tabs>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
