import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class GalleryAPI {
    public static async editPortfolio(id: string, body: {}) {
        return APIClient.ezoom.put(`/galleries/${id}`, body);
    }

    public static async getGalleries(id: string) {
        return APIClient.ezoom.get(`/public/search/galleries?show_hidden=true&portfolio=${id}`);
    }

    public static async getGalleriesPagination(id: string, page: number) {
        return APIClient.ezoom.get(`/public/search/galleries?show_hidden=true&portfolio=${id}&page=${page}`);
    }
}
