import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "@headlessui/react";
import { Input, Textarea } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ServicesAPI from "../../services/API/clients/ServicesAPI";
import ModalWrapper from "./ModalWrapper";
import PortfolioAPI from "../../services/API/clients/PortfolioAPI";

export default function ModalEditService({ open, setOpen, currentId, data }: Readonly<{ open: boolean; setOpen: () => void, currentId: string, data: { title: string, description: string } }>) {
    const { t } = useTranslation();
    const [title, setTitle] = useState<string>(data.title);
    const [description, setDescription] = useState<string>(data.description);

    useEffect(() => {
        setTitle(data.title);
        setDescription(data.description);
    }, [data]);

    const handleSubmit = async () => {
        if (title === "") {
            toast.warn(t("titleEmpty"));
            return;
        }
        if (currentId === "")
            return;
        const data = {
            title: title,
            ...(description !== "" && { description: description })
        };
        const response = await PortfolioAPI.putService(currentId ?? "", data);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("serviceEdited"));
            setOpen();
        } else {
            toast.error(t("serviceNotEdited"));
        }
    };

    return (
        <ModalWrapper open={open} setOpen={setOpen}>
            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white p-6 py-12 text-left align-middle shadow-xl transition-all max-w-3xl">
                <Dialog.Title as="h3" className="text-3xl font-medium leading-6 text-gray-900 text-center mb-4">
                    {t("editService")}
                </Dialog.Title>
                <FontAwesomeIcon
                    icon="x"
                    onClick={() => {
                        setOpen();
                    }}
                    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                />

                <div className="w-[400px] relative flex flex-col gap-3">
                    <Input
                        crossOrigin=""
                        type="text"
                        color="orange"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        label={t("title")}
                    />
                    <Textarea
                        color="orange"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        label={t("description")}
                    />
                </div>

                <div className="flex justify-center gap-4 mt-6">
                    <button
                        type="button"
                        className="rounded-full text-white bg-orange-500 hover:bg-orange-200 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => handleSubmit()}
                    >
                        {t("confirm")}
                    </button>
                    <button
                        type="button"
                        className="rounded-full text-black border bg-white hover:bg-gray-50 px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans"
                        onClick={() => setOpen()}
                    >
                        {t("cancel")}
                    </button>
                </div>
            </Dialog.Panel>
        </ModalWrapper>
    );
}
