export const Headers = [
    {
        id: "company",
        col: "company",
    },
    {
        id: "email",
        col: "email",
    },
    {
        id: "firstname",
        col: "firstname",
    },
    {
        id: "lastname",
        col: "lastname",
    }
];