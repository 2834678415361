import APIClient from "../APIClients";

export default class OrderAPI {
    public static async orders(query?: string) {
        return APIClient.ezoom.get(`/detailed-orders${query && query !== undefined && query !== "" ? `?query=${query}` : ""}`);
    }

    public static async ordersPagination(page: number, query: string) {
        return APIClient.ezoom.get(`/detailed-orders?page=${page}${query && query !== undefined && query !== "" ? `&query=${query}` : ""}`);
    }

    public static async order(id: string) {
        return APIClient.ezoom.get(`/orders/${id}`);
    }

    public static async orderTransaction(id: string) {
        return APIClient.ezoom.get(`/order-transactions?order=${id}`);
    }

    public static async quittance(id: string) {
        return APIClient.ezoom.getPdf(`/orders/${id}`);
    }

    public static async getContractsSales(id: string) {
        return APIClient.ezoom.get(`/sales?order=${id}`);
    }

    public static async quittanceSeller(id: string) {
        return APIClient.ezoom.getPdf(`/sales/${id}`);
    }
}