import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface Props {
    handlePageChange: (page: number) => void;
    itemsPerPage: number;
    currentPage: number;
    totalItems: number;
    startIndex: number;
    endIndex: number;
}

const Pagination: React.FC<Props> = ({ handlePageChange, itemsPerPage, currentPage, totalItems, startIndex, endIndex }) => {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const generatePageNumbers = () => {
        const pageNumbers: (number | string)[] = [];

        if (totalPages <= 7) {
            // Show all pages if the total is small
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            // Add the first page
            pageNumbers.push(1);

            if (currentPage > 3) {
                // Add ellipsis if the current page is far from the first page
                pageNumbers.push("...");
            }

            // Add the current page and its neighbors
            for (let i = Math.max(2, currentPage - 1); i <= Math.min(totalPages - 1, currentPage + 1); i++) {
                pageNumbers.push(i);
            }

            if (currentPage < totalPages - 2) {
                // Add ellipsis if the current page is far from the last page
                pageNumbers.push("...");
            }

            // Add the last page
            pageNumbers.push(totalPages);
        }

        return pageNumbers;
    };

    const pageNumbers = generatePageNumbers();

    return (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
            <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm text-gray-700">
                        <span className="font-medium">{startIndex + 1}</span> à <span className="font-medium">{endIndex + 1}</span> de{" "}
                        <span className="font-medium">{totalItems}</span> résultats
                    </p>
                </div>
                <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button
                            className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            disabled={currentPage === 1}
                            onClick={() => handlePageChange(currentPage - 1)}
                        >
                            <span className="sr-only">Previous</span>
                            <FontAwesomeIcon icon="chevron-left" className="h-3 w-5" aria-hidden="true" />
                        </button>
                        {pageNumbers.map((page, index) =>
                            typeof page === "number" ? (
                                <button
                                    key={index + 1}
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold ${
                                        currentPage === index + 1
                                            ? "bg-orange-500 text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
                                    }`}
                                >
                                    {page}
                                </button>
                            ) : (
                                <span
                                    key={index}
                                    className={`relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0`}
                                >
                                    {page}
                                </span>
                            )
                        )}
                        <button
                            className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                            disabled={endIndex === totalItems - 1}
                            onClick={() => handlePageChange(currentPage + 1)}
                        >
                            <span className="sr-only">Next</span>
                            <FontAwesomeIcon icon="chevron-right" className="h-3 w-5" aria-hidden="true" />
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
