export const Headers = [
    {
        id: "id",
        col: "id",
    },
    {
        id: "actif",
        col: "enabled",
    },
    {
        id: "pdp",
        col: "portfolio?.picture"
    },
    {
        id: "firstname",
        col: "firstname",
    },
    {
        id: "lastname",
        col: "lastname",
    },
    {
        id: "pseudo",
        col: "portfolio?.name",
    },
    {
        id: "company",
        col: "company",
    },
    {
        id: "birthdate",
        col: "birthdate",
    },
    {
        id: "email",
        col: "email",
    },
    {
        id: "phone",
        col: "phone",
    },
    {
        id: "iban",
        col: "iban",
    },
    {
        id: "bic",
        col: "bic",
    },
    {
        id: "accountPlan",
        col: "plan",
    }
];