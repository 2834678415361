import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

import Header from "../../components/NavBar/Header";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import TitlePage from "../../components/TitlePage";
import { Headers } from "./Header";
import { useEffect, useState } from "react";
import VouchersAPI from "../../services/API/clients/VouchersAPI";
import TableVouchers from "../../components/Tables/TableVouchers";
import { Button } from "@material-tailwind/react";
import ModalVouchers from "../../components/Modals/ModalVouchers";

export default function Vouchers() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("vouchers")}`;
    const [openModal, setOpenModal] = useState(false);
    const [dataVouchers, setDataVouchers] = useState<{
            pagination: {
                current: number,
                last: number,
                totalItems: number,
                parPage: number
            },
            data: {
                id: string,
                code: string,
                maxUsage: number,
                discountPct: number,
                usages: number
            }[]
        }>({
            pagination: {
                current: 0,
                last: 0,
                totalItems: 0,
                parPage: 0
            },
            data: []
        });

    const fetchData = async () => {
        const response = await VouchersAPI.getVouchers();
        if (response.status === 200) {
            setDataVouchers(response.body);
        }
    }

    const callNextVouchers = async (page: number) => {
        const response = await VouchersAPI.getVouchers(page);
        if (response.status === 200) {
            setDataVouchers(response.body);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const handleOpenModal = () => {
        setOpenModal(!openModal);
        callNextVouchers(dataVouchers.pagination.current);
    }

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <ModalVouchers open={openModal} setOpen={handleOpenModal} />
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage><FontAwesomeIcon icon="ticket" className="text-orange-500" /> {t("vouchers")}</TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                        <div className="w-full mx-auto px-4 md:px-8 flex flex-col gap-4">
                        <div className="w-fit">
                            <Button placeholder="" color="orange" onClick={() => setOpenModal(true)}>{t("newVoucher")}</Button>
                        </div>
                        {dataVouchers.pagination.totalItems > 0 ? (
                            <TableVouchers
                                header={Headers}
                                data={dataVouchers.data}
                                itemsPerPage={dataVouchers.pagination}
                                callPictures={callNextVouchers}
                            />
                        ) : (
                            <div className="flex flex-col items-center justify-center w-full h-[300px] bg-white rounded-md shadow-md">
                                <p className="text-gray-500 text-2xl font-semibold">{t("noVouchers")}</p>
                            </div>
                        )}
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
