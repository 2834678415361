import { ToastContainer } from 'react-toastify';
import { GoogleOAuthProvider } from "@react-oauth/google";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import Signin from './Signin/Signin';
import Newsletter from './Newsletter/Newsletter';
import Page404 from './Bundle/404/Page404';
import Admin from './Admin/Admin';
import Users from './Users/Users';
import CardDetail from './Users/CardDetail';
import Pictures from './Pictures/Pictures';
import Vouchers from './Vouchers/Vouchers';
import Settings from './Settings/Settings';
import Subscriptions from './Subscriptions/Subscriptions';
import SubscriptionDetail from './Subscriptions/SubscriptionDetail';
import Orders from './Order/Orders';
import OrderDetail from './Order/OrderDetail';
import TransactionDetails from './Transactions/TransactionsDetail';
import Paiements from './Paiements/Paiements';
import Support from './Support/Support';
import CardEdit from './Users/CardEdit';

const token = localStorage.getItem("token");
const roles = localStorage.getItem("roles");
const clientId = process.env.REACT_APP_GOOGLE_CODE_OAUTH ?? ""

export default function App() {
    return (
        <BrowserRouter>
            <GoogleOAuthProvider clientId={clientId}>
                <Routes>
                    <Route path="/" element={<Signin />} />

                    <Route path="/admin" element={token ? <Admin /> : <Navigate to="/" />} />
                    <Route path="/newsletter" element={token ? <Newsletter /> : <Navigate to="/" />} />
                    <Route path="/users" element={token ? <Users /> : <Navigate to="/" />} />
                    <Route path="/user/:id" element={token ? <CardDetail /> : <Navigate to="/" />} />
                    <Route path="/user/:id/edit" element={token ? <CardEdit /> : <Navigate to="/" />} />
                    <Route path="/pictures" element={token ? <Pictures /> : <Navigate to="/" />} />
                    <Route path="/subscriptions" element={token ? <Subscriptions /> : <Navigate to="/" />} />
                    <Route path="/subscription/:id" element={token ? <SubscriptionDetail /> : <Navigate to="/" />} />
                    <Route path="/orders" element={token ? <Orders /> : <Navigate to="/" />} />
                    <Route path="/order/:id" element={token ? <OrderDetail /> : <Navigate to="/" />} />
                    <Route path="/transaction/order/:order" element={token ? <TransactionDetails /> : <Navigate to="/" />} />
                    <Route path="/transaction/subscription/:subscription" element={token ? <TransactionDetails /> : <Navigate to="/" />} />
                    <Route path="/vouchers" element={token && (roles && roles.includes("role:admin")) ? <Vouchers /> : <Navigate to="/" />} />
                    <Route path="/settings" element={token && (roles && roles.includes("role:admin")) ? <Settings /> : <Navigate to="/" />} />
                    <Route path="/paiements" element={token ? <Paiements /> : <Navigate to="/" />} />
                    <Route path="/communications" element={token ? <Support /> : <Navigate to="/" />} />

                    <Route path="/*" element={<Page404 />} />
                </Routes>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </GoogleOAuthProvider>
        </BrowserRouter>
    )
}