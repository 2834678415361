import { Button, Option, Select, Spinner, Typography } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";

import PicturesAPI from "../../services/API/clients/PicturesAPI";
import TablePictures from "../../components/Tables/TablePictures";
import { Headers } from "./Headers";
import ModalRefusePicture from "../../components/Modals/ModalRefusePicture";
import { ElementPictureProps } from "../../components/Interface/ElementPictureProps";

export default function Validation() {
    const { t } = useTranslation();
    const [selectAll, setSelectAll] = React.useState<boolean>(false);
    const [nbrItemsPerPage, setNbrItemsPerPage] = React.useState<string>("20");
    const [checked, setChecked] = React.useState<string[]>([]);
    const [valueResearch, setValueResearch] = React.useState<string>("sale");
    const [openRefuse, setOpenRefuse] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [verifPictures, setVerifPictures] = React.useState<{
        pagination: { current: number, last: number, totalItems: number, parPage: number},
        data: ElementPictureProps[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });

    const getList = async () => {
        const responseVerif = await PicturesAPI.validationPictures(nbrItemsPerPage, "reason=sale");
        if (responseVerif.status === 200) {
            setVerifPictures(responseVerif.body);
        }
    }

    useEffect(() => {
        getList()
    }, [openRefuse])

    useEffect(() => {
        getList()
    }, [])

    const fetchData = async () => {
        const responseVerif = await PicturesAPI.validationPictures(nbrItemsPerPage, `reason=${valueResearch}`);
        if (responseVerif.status === 200) {
            setVerifPictures(responseVerif.body);
        }
    }

    useEffect(() => {
        if (valueResearch === "") return;
        fetchData();
    }, [valueResearch])

    const callValidations = async (page: number) => {
        const response = await PicturesAPI.validationPicturesPaginations(nbrItemsPerPage, valueResearch, page);
        if (response.status === 200) {
            setVerifPictures(response.body);
        }
    }

    const handleMotif = async (motif: string) => {
        if (loading) return;
        setLoading(true)
        const datas = {
            action: motif
        }
        try {
            const checkIfAllIsOk = Promise.all(checked.map(async (check: string) => {
                await PicturesAPI.validatePicture(nbrItemsPerPage, check, datas);
            }))
            await checkIfAllIsOk;
            setChecked([]);
            callValidations(verifPictures.pagination.current);
            setLoading(false)
        } catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    const handleOpenIt = () => {
        setOpenRefuse(true)
    }

    useEffect(() => {
        if (valueResearch === "")
            getList();
        else
            fetchData();
    }, [nbrItemsPerPage])

    const selectOrUnSelectAll = () => {
        if (selectAll) {
            setChecked([]);
        } else {
            setChecked(verifPictures.data.map((data) => data.id));
        }
        setSelectAll(!selectAll ? true : false);
    }

    return (
        <>
            <ModalRefusePicture nbrItemsPerPage={nbrItemsPerPage} open={openRefuse} setOpen={() => setOpenRefuse(false)} currentId={checked} />
            <div className="flex flex-row items-center justify-between w-full gap-4">
                <Select
                    label={t("kindValidation")}
                    placeholder=""
                    value={valueResearch}
                    onChange={(e: string | undefined) => e && setValueResearch(e)}
                    color="orange"
                    className="bg-white"
                >
                    <Option value="sale">{t("sell")}</Option>
                    <Option value="nsfw">{t("nsfw")}</Option>
                    <Option value="keyword">{t("keywords")}</Option>
                </Select>
                <div className="w-52">
                    <Select
                        label={t("printNumber")}
                        color="orange"
                        onChange={(e) => setNbrItemsPerPage(e ?? "0")}
                        placeholder=""
                        className="bg-white"
                        value={nbrItemsPerPage}
                    >   
                        <Option value="10">{t("ten")}</Option>
                        <Option value="20">{t("twenty")}</Option>
                        <Option value="50">{t("fifty")}</Option>
                        <Option value="100">{t("hundred")}</Option>
                        <Option value="200">{t("twoHundred")}</Option>
                    </Select>
                </div>
            </div>
            {verifPictures?.data.length > 0 ? (
                <div className="flex flex-col gap-2 mt-4">
                    <TablePictures nbrItemsPerPage={nbrItemsPerPage} header={Headers} data={verifPictures.data} itemsPerPage={verifPictures.pagination} callPictures={callValidations} checked={checked} setChecked={setChecked} />
                    {loading ? (
                        <Spinner color="orange" />
                    ) : (
                            <div className="flex gap-2">
                                <Button placeholder="" variant="text" onClick={() => selectOrUnSelectAll()}>
                                    {selectAll ? t("deselectAll") : t("selectAll")}
                                </Button>
                                <Button placeholder="" onClick={() => handleMotif("accept")} color="green" variant="outlined" className="py-2 px-4">
                                    <FontAwesomeIcon icon="eye" className="mr-2" />
                                    {t("referencement")}
                                </Button>
                                <Button placeholder="" onClick={() => handleMotif("restrict")} color="green" variant="outlined" className="py-2 px-4">
                                    <FontAwesomeIcon icon="eye-slash" className="mr-2" />
                                    {t("noreferecement")}
                                </Button>
                                <Button placeholder="" onClick={() => handleMotif("nsfw")} color="yellow" variant="outlined" className="py-2 px-4">
                                    <FontAwesomeIcon icon="fire" className="mr-2" />
                                    {t("nsfw")}
                                </Button>
                                <Button placeholder="" onClick={() => handleOpenIt()} color="red" variant="outlined" className="py-2 px-4">
                                    <FontAwesomeIcon icon="x" className="mr-2" />
                                    {t("refuse")}
                                </Button>
                            </div>
                        )
                    }
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100 mt-4">
                    <FontAwesomeIcon icon="images" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                    <Typography placeholder="" className="text-base text-gray-700 mb-4">
                        {t("noPictures")}
                    </Typography>
                </div>
            )}
        </>
    );
}
