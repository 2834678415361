import { Typography } from "@material-tailwind/react";
import React from "react";

import Pagination from "./Pagination";

import { useTranslation } from "react-i18next";

interface Props {
    header: { col: string; id: string }[];
    data: {
        id: string,
        code: string,
        maxUsage: number,
        discountPct: number,
        usages: number
    }[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: (page: number) => void;
}

const TableVouchers: React.FC<Props> = ({ header, data, itemsPerPage, callPictures }) => {
    const { t } = useTranslation();

    return (
        <div className="table-container">
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            {header?.map((column: { col: string; id: string }, index: number) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        placeholder=""
                                        variant="small"
                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                            index === header.length - 1 ? "text-center" : "text-left"
                                        }`}
                                    >
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {data?.map((element, key: number) => {
                            const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                            return (
                                <tr key={element.id} className={`example-element-row`}>
                                    {header?.map((column: { id: string; col: string }) => {
                                        return (
                                            <td key={column.id} className={className}>
                                                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                                                    {element[column.col as keyof typeof element]}
                                                </Typography>
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callPictures}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

export default TableVouchers;
