import { IconButton, Typography } from "@material-tailwind/react";
import React from "react";

import Pagination from "./Pagination";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalSupport from "../Modals/ModalSupport";

interface Props {
    header: any;
    data: any;
    itemsPerPage: number;
}

const TableSupport: React.FC<Props> = ({ header, data, itemsPerPage }) => {
    const  { t } = useTranslation();
    const [currentPage, setCurrentPage] = React.useState(1);
    const [openModal, setOpenModal] = React.useState(false);
    const [selectedItem, setSelectedItem] = React.useState<{
        id: string,
        sender: {
            firstname: string,
            lastname: string,
            email: string
        },
        subject: string
    }>({
        id: "",
        sender: {
            firstname: "",
            lastname: "",
            email: ""
        },
        subject: ""
    });
    const totalItems = data.length;
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage - 1, totalItems - 1);
    const visibleItems = data.slice(startIndex, endIndex + 1);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleOpenModal = () => {
        setOpenModal(!openModal);
    };

    return (
        <>
            <ModalSupport
                open={openModal}
                setOpen={handleOpenModal}
                data={selectedItem}
            />
            <div className="table-container max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            {header.map((column: any) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                            <th className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                    {t("actions")}
                                </Typography>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {visibleItems !== undefined &&
                            visibleItems.map((element: any, key: number) => {
                                const className = `py-3 px-5 ${key === visibleItems.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                return newFunction(element, header, className, t, setOpenModal, setSelectedItem);
                            })}
                    </tbody>
                </table>
                <Pagination
                    handlePageChange={handlePageChange}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    totalItems={totalItems}
                    startIndex={startIndex}
                    endIndex={endIndex}
                />
            </div>
        </>
    );
};

export default TableSupport;
function newFunction(element: any, header: any, className: string, t: any, setOpenModal: any, setSelectedItem: any) {
    
    return (
        <React.Fragment key={element.id}>
            <tr
                className={`example-element-row ${element.deletedAt !== "1970-01-01T00:00:00.000Z" && element.deletedAt !== undefined && "bg-gray-200"}`}
            >
                {header.map((column: any, i: number) => {
                    return (
                        <td key={column.id} className={className}>
                            <Typography
                                placeholder=""
                                variant="small"
                                color="blue-gray"
                                className={
                                    "text-gray-500 max-w-[600px]"
                                }
                            >
                                {column.col === "subject" 
                                    ? t(element.subject) 
                                    : column.col === "firstname" 
                                        ? (element?.sender?.firstname + " " + element?.sender?.lastname)
                                        : element.sender.email ?? "/"
                                }
                            </Typography>
                        </td>
                    );
                })}
                <td className={className}>
                    <Typography
                        placeholder=""
                        variant="small"
                        color="blue-gray"
                        className="text-gray-500"
                    >
                        <IconButton
                            placeholder=""
                            color="orange"
                            onClick={() => {
                                setOpenModal(true);
                                setSelectedItem(element);
                            }}
                        >
                            <FontAwesomeIcon icon="envelope" />   
                        </IconButton>
                    </Typography>
                </td>
            </tr>
        </React.Fragment>
    );
}
