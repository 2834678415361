import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/NavBar/Header";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import UsersAPI from "../../services/API/clients/UsersAPI";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Chip, Typography, Spinner, Tooltip, Input, Textarea, Alert, Tabs, TabsHeader, Tab, TabsBody, TabPanel } from "@material-tailwind/react";
import DefaultProfilePicture from "../../assets/img/default-profile.png";
import { toast } from "react-toastify";
import { HeaderCardDetailEdit } from "./HeaderCardDetailEdit";
import { Storage } from "../../services/storage";
import CountryAPI from "../../services/API/clients/CountryAPI";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import PlaceAPI from "../../services/API/clients/PlaceAPI";
import { v4 as uuidv4 } from "uuid";
import PortfolioAPI from "../../services/API/clients/PortfolioAPI";
import GalleryAPI from "../../services/API/clients/GalleryAPI";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import TableUserGalleries from "../../components/Tables/TableUserGalleries";
import { GalleryProps } from "../../components/Interface/GalleriesProps";
import { ServiceProps } from "../../components/Interface/ServiceProps";
import TableUserServices from "../../components/Tables/TableUserServices";
import { TestimonialProps } from "../../components/Interface/TestimonialProps";
import TableUserTestimonials from "../../components/Tables/TableUserTestimonials";

export const PlaceCustomStyle = {
    menuPortal: (base: any) => ({
        ...base,
        zIndex: 9999,
    }),
    menubar: (base: any) => ({
        ...base,
    }),
    menuList: (base: any) => ({
        ...base,
        padding: "0 0.5rem",
    }),
    control: (baseStyles: any, state: { isFocused: boolean, isDisabled: boolean }) => ({
        ...baseStyles,
        boxShadow: state.isFocused ? "0 0 0 1px rgb(239, 147, 53)" : "",
        borderColor: state.isFocused ? "rgb(239, 147, 53)" : state.isDisabled ? "rgb(236, 239, 241)" : "rgb(176, 190, 197)",
        backgroundColor: state.isDisabled ? "rgb(236, 239, 241)" : "white",
        color: state.isDisabled ? "rgb(69, 90, 100)" : "rgb(128, 128, 128)",
        "&:hover": {
            borderColor: state.isFocused ? "rgb(239, 147, 53)" : "rgb(176, 190, 197)",
        },
        fontSize: "14px"
    }),
    option: (base: any, state: { isSelected: boolean; isFocused: boolean }) => ({
        ...base,
        background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "white",
        color: state.isSelected ? "white" : "black",
        "&:hover": {
            background: state.isSelected ? "linear-gradient(#ef9335, #F5BA56)" : "lightgray",
            color: state.isSelected ? "white" : "black",
        },
        borderRadius: "0.375rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
    }),
    placeholder: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
    singleValue: (base: any) => ({
        ...base,
        color: "rgb(96, 125, 139)",
        fontSize: "14px"
    }),
};

const roles = Storage.getRoles();

const URL_REDIRECT = process.env.REACT_APP_URL_REDIRECT

export default function CardEdit() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [user, setUser] = useState<any>({});
    const [userEdit, setUserEdit] = useState<any>({});
    const [dataPortfolio, setDataPortfolio] = useState<any>({});
    const [defaultPlan, setDefaultPlan] = useState<string>("");
    const [dataServices, setDataServices] = useState<ServiceProps>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });
    const [dataGalleries, setDataGalleries] = useState<GalleryProps>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });
    const [dataTestimonials, setDataTestimonials] = useState<TestimonialProps>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [options, setOptions] = useState<any[]>([])
    const [uuid, setUuid] = useState<string>("");

    useEffect(() => {
        setUuid(uuidv4());
    }, []);

    const loadOptions = async () => {
        const response = await CountryAPI.place()
        if (response.status === 200) {
            const listFiltered = response.body.map((place: any) => {
                return {
                    value: place.code,
                    label: place.frLabel
                }
            })
            setOptions(listFiltered)
        }
    }

    useEffect(() => {
        loadOptions()
    }, [])

    const handleChange = async (value: any) => {
        setUserEdit({... userEdit, country: { code: value.value }})
    }

    const loadOptionsPlace = async (inputValue: string) => {
        if (uuid === "") return;
        if (inputValue.length === 0) return;
        const response = await PlaceAPI.place(uuid, inputValue)
        if (response.status === 200) {
            return response.body.map((place: any) => {
                return {
                    value: place.id,
                    label: place.label
                }
            })
        } else {
            console.error("Error")
        }
    }

    const handleChangePlace = async (value: any) => {
        const data = {
            refId: value.value
        }

        const resp = await PlaceAPI.newPlace(uuid, data)
        if (resp.status === 200 || resp.status === 201) {
            setUserEdit({ ...userEdit, place: { id: resp.body.id, label: resp.body.label ?? "" } })
        } else
            console.error("Error")
    }

    const returnColor = () => {
        if (user?.plan === undefined) return;
        if (user?.plan === "PRO") return "bg-black text-white";
        else if (user?.plan === "PHOTOGRAPHER") return " bg-white text-black border border-black";
        else if (user?.plan === "PREMIUM") return " bg-white text-black border border-black";
        else return " bg-white text-black border border-black";
    };

    const returnValue = () => {
        if (user?.plan === undefined) return;
        if (user?.plan === "PRO") return t("pro");
        else if (user?.plan === "PHOTOGRAPHER") return t("photographer");
        else if (user?.plan === "PREMIUM") return t("premium");
        else return t("user");
    };

    const returnIcon = () => {
        if (user?.plan === undefined) return;
        if (user?.plan === "PRO") return <FontAwesomeIcon icon="crown" className="h-3.5 mt-0.5" />;
        else if (user?.plan === "PREMIUM") return <FontAwesomeIcon icon="star" className="h-3.5 mt-0.5" />;
        else return;
    };

    const fetchData = async () => {
        if (!id) return;
        const response = await UsersAPI.user(id);
        if (response.status === 200) {
            setUser(response.body);
            setUserEdit(response.body);
            setDefaultPlan(response.body.plan);
        }
        const respPortfolio = await PortfolioAPI.getPortfolio(response.body.portfolio.id);
        if (respPortfolio.status === 200) {
            setDataPortfolio(respPortfolio.body)
        }
        const respServices = await PortfolioAPI.getServices(response.body.portfolio.id);
        if (respServices.status === 200) {
            setDataServices(respServices.body)
        }
        const respGalleries = await GalleryAPI.getGalleries(response.body.portfolio.id);
        if (respGalleries.status === 200) {
            setDataGalleries(respGalleries.body)
        }
        const respTestimonials = await PortfolioAPI.getTestimonials(response.body.portfolio.id);
        if (respTestimonials.status === 200) {
            setDataTestimonials(respTestimonials.body)
        }

        setIsLoading(false);
    };

    const fetchDataTestimonials = async () => {
        if (!user.portfolio.id) return;
        const respGalleries = await PortfolioAPI.getTestimonials(user.portfolio.id);
        if (respGalleries.status === 200) {
            setDataTestimonials(respGalleries.body)
        }
    }

    const fetchNextPageTestimonials = async () => {
        if (!user.portfolio.id) return;
        const respGalleries = await PortfolioAPI.getTestimonialsPagination(user.portfolio.id, dataGalleries.pagination.current + 1);
        if (respGalleries.status === 200) {
            setDataTestimonials(respGalleries.body)
        }
    }

    const fetchDataService = async () => {
        if (!user.portfolio.id) return;
        const respGalleries = await PortfolioAPI.getServices(user.portfolio.id);
        if (respGalleries.status === 200) {
            setDataServices(respGalleries.body)
        }
    }

    const fetchNextPageService = async () => {
        if (!user.portfolio.id) return;
        const respGalleries = await PortfolioAPI.getServicesPagination(user.portfolio.id, dataGalleries.pagination.current + 1);
        if (respGalleries.status === 200) {
            setDataServices(respGalleries.body)
        }
    }

    const fetchDataGallery = async () => {
        if (!user.portfolio.id) return;
        const respGalleries = await GalleryAPI.getGalleries(user.portfolio.id);
        if (respGalleries.status === 200) {
            setDataGalleries(respGalleries.body)
        }
    }

    const fetchNextPageGallery = async () => {
        if (!user.portfolio.id) return;
        const respGalleries = await GalleryAPI.getGalleriesPagination(user.portfolio.id, dataGalleries.pagination.current + 1);
        if (respGalleries.status === 200) {
            setDataGalleries(respGalleries.body)
        }
    }

    useEffect(() => {
        fetchData();
    }, [id]);

    const disabledUser = async () => {
        if (!id) return;
        const datas = {
            enabled: !user?.enabled,
        }
        const response = await UsersAPI.changeStatus(id, datas);
        if (response.status === 200 || response.status === 201) {
            if (!user?.enabled) 
                toast.success(t("successEnable"));
            else
                toast.success(t("successDisable"));
            fetchData();
        } else {
            toast.error(t("errorDisable"));
        }
    };

    const updateRolesUser = async (value: string) => {
        let roles = []
        if (value === "modo") {
            roles.push("role:user")
            roles.push("role:moderator")
        } else if (value === "user") {
            roles.push("role:user")
        } else if (value === "admin") {
            roles.push("role:user")
            roles.push("role:moderator")
            roles.push("role:admin")
        }
        const response = await UsersAPI.updateRoles(id ?? "", {roles : roles});
        if (response.status === 200 || response.status === 201) {
            toast.success(t("successRole"))
            fetchData();
        } else {
            toast.error(t("errorRole"))
        }
    }

    const handleSubmit = async () => {
        console.log(userEdit.plan)
        console.log(defaultPlan)      

        if (defaultPlan !== userEdit.plan) {
            const dataPlan = {
                owner: `/api/users/${userEdit.id}`,
                plan: userEdit.plan
            }
            const responsePlan = await UsersAPI.changePlan(dataPlan);
            toast.success(t("planUpdate"));
            fetchData();
        }

        const datas = {
            firstname: userEdit.firstname,
            lastname: userEdit.lastname,
            ...(userEdit.company && userEdit.company !== undefined && userEdit.company !== "" && { company: userEdit.company }),
            ...(userEdit.iban && userEdit.iban !== undefined && userEdit.iban !== "" && { iban: userEdit.iban.replace(/ /g, "") }),
            ...(userEdit.swiftbic && userEdit.swiftbic !== undefined && userEdit.swiftbic !== "" && { bic: userEdit.swiftbic }),
            ...(userEdit.place && userEdit.place.id && userEdit.place.id !== undefined && userEdit.place.id !== "" && { place: `/api/public/places/${userEdit.place.id}` }),
            ...(userEdit.country && userEdit.country !== undefined && userEdit.country !== "" && { country: `/api/public/countries/${userEdit.country.code}` }),
            ...(userEdit.phone && userEdit.phone !== undefined && userEdit.phone !== "" && { phone: userEdit.phone.replace(/\s/g, "") }),
            birthdate: userEdit.birthdate
        }
        const response = await UsersAPI.modifyUser(id ?? "", datas);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("successUpdate"));
            fetchData();
        } else {
            toast.error(t("errorUpdate"));
        }  

        const datasPortfolio = {
            name: dataPortfolio.name,
            description: dataPortfolio.description
        }
        const responsePortfolio = await PortfolioAPI.editPortfolio(userEdit.portfolio.id, datasPortfolio);
        if (responsePortfolio.status === 200 || responsePortfolio.status === 201) {
            toast.success(t("portfolioUpdated"));
            fetchData();
        } else {
            toast.error(t("portfolioErrorUpdate"));
        }
    }

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="user" className="text-orange-500" /> {t("usersEdit")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full mx-auto px-4 md:px-8 flex flex-col gap-4 md:gap-0 md:flex-row flex-wrap">
                        <div className="w-full md:w-96 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-center">
                                <CardHeader placeholder="" floated={false} className="h-[150px] w-[150px] rounded-full mx-auto">
                                    <img
                                        src={userEdit?.portfolio?.picture ?? DefaultProfilePicture}
                                        alt="profile"
                                        className="w-full h-full object-cover object-center rounded-full"
                                    />
                                </CardHeader>
                                <CardBody placeholder="" className="text-center">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2">
                                        {userEdit?.firstname} {userEdit?.lastname}
                                    </Typography>
                                    <Typography placeholder="" color="blue-gray" className="font-medium" textGradient>
                                        {userEdit?.email}
                                    </Typography>
                                    <Typography placeholder="" color="blue-gray" className="font-medium w-fit mx-auto mt-2">
                                        <Chip className={`w-fit h-fit ${returnColor()}`} variant="ghost" value={returnValue()} icon={returnIcon()} />
                                    </Typography>
                                </CardBody>
                                {isLoading ? (
                                    <CardFooter placeholder="" className="flex justify-center gap-4 pt-2">
                                        <Spinner color="orange" />
                                    </CardFooter>
                                ) : (
                                    <CardFooter placeholder="" className="flex flex-col justify-center gap-6 pt-2">
                                        <hr />
                                        <div className="flex gap-4 justify-between px-4">
                                            {userEdit?.portfolio?.publicLink ? (
                                                <a href={`${URL_REDIRECT}${userEdit?.portfolio?.publicLink}/photos`}>
                                                    <Button placeholder="" color="orange">
                                                        {t("portfolio")}
                                                    </Button>
                                                </a>
                                            ) : (
                                                <Tooltip content={t("noPortfolio")} placement="right">
                                                    <Button placeholder="" color="orange" disabled>
                                                        {t("portfolio")}
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Button placeholder="" color={!userEdit?.enabled ? "green" : "red"} onClick={() => disabledUser()}>
                                                {t(!userEdit?.enabled ? "ENABLE" : "DISABLE")}
                                            </Button>
                                        </div>
                                        <div className="flex flex-col gap-2 px-4 justify-around">
                                            <Button placeholder="" color="green" variant="outlined" onClick={() => handleSubmit()}>
                                                {t("save")}
                                            </Button>
                                        </div>
                                    </CardFooter>
                                )}
                            </Card>
                        </div>
                        <div className="w-full md:w-[calc(100%-24rem)] md:pl-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full">
                                <CardBody placeholder="" className="overflow-x-auto">
                                    <table className="h-full w-full table-auto text-left">
                                        {HeaderCardDetailEdit.map((exif) => {
                                            return (
                                                <tr key={exif.id}>
                                                    <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                                        <Typography
                                                            placeholder=""
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal leading-none opacity-70 rounded-tl"
                                                        >
                                                            {t(exif.id)}
                                                        </Typography>
                                                    </th>
                                                    <td className="p-4 border-b border-blue-gray-100/80">
                                                        {exif.id === "accountPlan" ? (
                                                            <>
                                                                {(roles && roles.length > 0 && !roles.includes("role:admin")) ? (
                                                                    <Input placeholder="" crossOrigin="" type="text" value={t(userEdit.plan)} disabled />
                                                                ) : (
                                                                    <select
                                                                        value={userEdit.plan}
                                                                        className="w-full bg-white border-b border-blue-gray-500 pb-2"
                                                                        onChange={(e) => {
                                                                            setUserEdit({ ...user, plan: e.target.value });
                                                                        }}
                                                                    >
                                                                        <option value="PRO">
                                                                            PRO
                                                                        </option>
                                                                        <option value="PREMIUM">
                                                                            PREMIUM
                                                                        </option>
                                                                        <option value="USER" >
                                                                            Utilisateur
                                                                        </option>
                                                                    </select>
                                                                )}
                                                            </>
                                                        ) : exif.id === "rights" ? (
                                                            <>
                                                                {(roles && roles.length > 0 && !roles.includes("role:admin")) ? (
                                                                    <Input 
                                                                        placeholder="" 
                                                                        crossOrigin="" 
                                                                        type="text" 
                                                                        value={
                                                                            userEdit.roles && userEdit.roles.length > 0
                                                                                ? userEdit.roles.includes("role:admin")
                                                                                    ? t("admin")
                                                                                    : userEdit.roles.includes("role:moderator")
                                                                                    ? t("modo")
                                                                                    : t("user")
                                                                                : t("user")
                                                                        }
                                                                        disabled 
                                                                    />
                                                                ) : (
                                                                    <div className="flex flex-wrap items-center gap-2">
                                                                        <Button placeholder="" color="orange" size="sm" variant="outlined" disabled={userEdit.roles && userEdit.roles.length > 0 && (!userEdit.roles.includes("role:moderator") && !userEdit.roles.includes("role:admin"))} onClick={() => updateRolesUser("user")}>
                                                                            {t("user")}
                                                                        </Button>
                                                                        <Button placeholder="" color="orange" size="sm" variant="outlined" disabled={userEdit.roles && userEdit.roles.length > 0 && (userEdit.roles.includes("role:moderator") && !userEdit.roles.includes("role:admin"))} onClick={() => updateRolesUser("modo")}>
                                                                            {t("modo")}
                                                                        </Button>
                                                                        <Button placeholder="" color="orange" size="sm" variant="outlined" disabled={userEdit.roles && userEdit.roles.length > 0 && (userEdit.roles.includes("role:admin"))} onClick={() => updateRolesUser("admin")}>
                                                                            {t("admin")}
                                                                        </Button>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : exif.id === "country" ? (
                                                            <Select
                                                                options={options} 
                                                                menuPortalTarget={document.body}
                                                                menuPosition="fixed"
                                                                styles={PlaceCustomStyle}
                                                                className="w-full relative top-0 bg-white border-orange-500"
                                                                placeholder={t("country")}
                                                                onChange={handleChange}
                                                                value={userEdit?.country?.code && userEdit?.country?.code !== undefined && userEdit.country.code !== "" ? options.find((option) => userEdit.country.code && option.value === userEdit.country.code) : options.find((option) => option.value === "CHE")}
                                                                name="lieu"
                                                            />
                                                        ) : exif.id === "address" ? (
                                                            <AsyncSelect 
                                                                cacheOptions 
                                                                loadOptions={loadOptionsPlace} 
                                                                defaultOptions 
                                                                menuPortalTarget={document.body}
                                                                menuPosition="fixed"
                                                                styles={PlaceCustomStyle}
                                                                className="w-full relative top-0 bg-white border-orange-500"
                                                                placeholder={t("address")}
                                                                onChange={handleChangePlace}
                                                                value={userEdit?.place?.id && { value: userEdit?.place?.id, label: userEdit?.place?.label }}
                                                                name="lieu"
                                                                noOptionsMessage={() => t("noplaces")}
                                                            />
                                                        ) : exif.id === "birthdate" ? (
                                                            <Input
                                                                type="date"
                                                                value={userEdit.birthdate}
                                                                color="orange"
                                                                onChange={(e) => setUserEdit({ ...user, [exif.col]: e.target.value })}
                                                                placeholder=""
                                                                crossOrigin=""
                                                                variant="standard"
                                                            />
                                                        ) : exif.id === "phone" ? (
                                                            <IntlTelInput
                                                                inputClassName="z-50 peer w-full max-w-full h-full bg-white text-blue-gray-700 font-sans font-normal outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 border focus:border-2 text-sm px-3 py-2.5 focus:py-2 rounded-[7px] border-blue-gray-200 focus:border-deep-purple-500"
                                                                defaultCountry="ch"
                                                                onPhoneNumberChange={(_status, _value, _countryData, number, _id) => setUserEdit({... userEdit, phone: number})}
                                                                // placeholder={value !== "" ? value : "078 123 45 67"}
                                                                value={userEdit.phone}
                                                            />
                                                        ) : (
                                                            <Input
                                                                type="text"
                                                                value={exif.id === "Pseudo" ? userEdit.portfolio?.name : exif.id === "Pays" ? userEdit?.country?.frLabel : exif.id === "Adresse" ? userEdit?.place?.label : user[exif.col]}
                                                                color="orange"
                                                                onChange={(e) => setUserEdit({ ...user, [exif.col]: e.target.value })}
                                                                placeholder=""
                                                                crossOrigin=""
                                                                variant="standard"
                                                            />
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </table>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="w-full md:w-1/3 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Tabs value="portfolio">
                                        <TabsHeader placeholder="">
                                            <Tab placeholder="" value="portfolio">
                                                {t("portfolio")}
                                            </Tab>
                                            <Tab placeholder="" value="testimonial">
                                                {t("testimonials")}
                                            </Tab>
                                        </TabsHeader>
                                        <TabsBody placeholder="">
                                            <TabPanel value="portfolio" className="mt-2">
                                                <div className="flex flex-col gap-4">
                                                    <Input 
                                                        crossOrigin=""
                                                        type="text" 
                                                        value={dataPortfolio?.name} 
                                                        color="orange" 
                                                        onChange={(e) => setDataPortfolio({ ...dataPortfolio, name: e.target.value })} 
                                                        label={t("pseudo")} 
                                                    />
                                                    <Textarea 
                                                        value={dataPortfolio?.description} 
                                                        color="orange" 
                                                        onChange={(e) => setDataPortfolio({ ...dataPortfolio, description: e.target.value })} 
                                                        label={t("description")}
                                                    />
                                                </div>
                                            </TabPanel>
                                            <TabPanel value="testimonial" className="mt-2">
                                                {dataTestimonials.pagination.totalItems === 0 ? (
                                                    <Alert variant="ghost" color="gray" className="text-center">
                                                        {t("noTestimonials")}
                                                    </Alert>
                                                ) : (
                                                    <TableUserTestimonials
                                                        data={dataTestimonials}
                                                        handleGetData={() => fetchDataTestimonials()}
                                                        nextPage={() => fetchNextPageTestimonials()}
                                                    />
                                                )}
                                            </TabPanel>
                                        </TabsBody>
                                    </Tabs>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="w-full md:w-1/3 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("services")}
                                    </Typography>
                                    {dataServices.pagination.totalItems === 0 ? (
                                        <Alert color="gray" variant="ghost" className="text-center">
                                            {t("noService")}
                                        </Alert>
                                    ) : (
                                        <div className="w-full overflow-x-auto">
                                            <TableUserServices
                                                data={dataServices}
                                                handleGetData={() => fetchDataService()}
                                                nextPage={() => fetchNextPageService()}
                                            />
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                        <div className="w-full md:w-1/3 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("gallerie")}
                                    </Typography>
                                    {dataGalleries.pagination.totalItems === 0 ? (
                                        <Alert color="gray" variant="ghost" className="text-center">
                                            {t("noGallery")}
                                        </Alert>
                                    ) : (
                                        <TableUserGalleries
                                            data={dataGalleries}
                                            handleGetData={() => fetchDataGallery()}
                                            nextPage={() => fetchNextPageGallery()}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
