import { IconButton, Typography } from "@material-tailwind/react";
import React from "react";

import Pagination from "./Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ModalVisuOriginalPath from "../Modals/ModalVisuOriginalPath";

interface Props {
    header: { col: string; id: string }[];
    data: any[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: (page: number) => void;
}

const TableSubs: React.FC<Props> = ({ header, data, itemsPerPage, callPictures }) => {
    const { t } = useTranslation();
    const [constantData, setConstantData] = React.useState<boolean[]>([]);

    const ImageReturning = ({ element }: { element: any }) => {
        return (
            <a href={`/user/${element?.owner?.id}`} className="flex items-center overflow-hidden rounded-md my-2" style={{ width: "75px", height: "75px" }}>
                <IconButton placeholder="" color="orange">
                    <FontAwesomeIcon icon="user" />
                </IconButton>
            </a>
        );
    };

    const IdReturning = ({ element }: { element: string }) => {
        const id = element;
        return (
            <p className="flex items-center rounded-md my-2">
                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                    {`${id}`}
                </Typography>
            </p>
        );
    };

    const TransactionReturning = ({ id, status }: { id: string, status: string }) => {
        return (
            <a href={`/subscription/${id}`} className="flex items-center rounded-md my-2 underline">
                <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                    {`${id === undefined ? "" : id}`}
                    <FontAwesomeIcon icon={status === "AUTHORIZED" ? "check" : "x"} className={`ml-2 text-${status === "AUTHORIZED" ? "green" : "red"}-500`} />
                </Typography>
            </a>
        );
    };

    const TypoReturning = ({
        element,
        column,
    }: {
        element: {
            id: string;
            picture: {
                id: string;
                publicPath?: string;
                minPath?: string;
                originalPath?: string;
            };
            reason: string;
        };
        column: { id: string; col: string };
    }) => {
        let content = element[column.col as keyof typeof element] ?? "/";
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }
        if (column.id === "createdAt") {
            content = moment(content).format("DD.MM.YYYY");
        }

        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {t(content)}
            </Typography>
        );
    };

    return (
        <div className="table-container">
            <div className="w-full overflow-x-auto max-h-[52vh]">
                <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                    <thead>
                        <tr>
                            {header?.map((column: { col: string; id: string }) => (
                                <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                    <Typography
                                        placeholder=""
                                        variant="small"
                                        className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap text-left`}
                                    >
                                        {t(column.id)}
                                    </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {data?.map((element: {
                        id: string;
                        picture: {
                            id: string;
                            publicPath?: string;
                            minPath?: string;
                            originalPath?: string;
                        };
                        reason: string;
                        lastTransaction: {
                            id: string,
                            status: string
                        },
                        initialTransaction: {
                            id: string,
                            status: string
                        }
                    }, key: number) => {
                        const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                        const handleOpenVisuOrga = () => {
                            const oldConstantData = constantData;
                            oldConstantData[key] = !oldConstantData[key];
                            setConstantData([...oldConstantData]);
                        };

                        return (
                            <>
                                <ModalVisuOriginalPath open={constantData?.[key] ?? false} setOpen={handleOpenVisuOrga} picture={element} />
                                <tr key={element.id} className={`example-element-row`}>
                                    {header?.map((column: { id: string; col: string }) => {
                                        return (
                                            <td key={column.id} className={className}>
                                                {column.id === "id" && <IdReturning element={element.id} />}
                                                {column.id === "owner" && <ImageReturning element={element} />}
                                                {(column.id === "lastTransaction") && <TransactionReturning id={element?.lastTransaction?.id} status={element?.lastTransaction?.status} />}
                                                {(column.id === "initialTransaction") && <TransactionReturning id={element?.initialTransaction?.id} status={element?.initialTransaction?.status} />}
                                                {column.id !== "owner" && column.id !== "id" && column.id !== "lastTransaction" && column.id !== "initialTransaction" && (
                                                    <TypoReturning element={element} column={column} />
                                                )}
                                            </td>
                                        );
                                    })}
                                </tr>
                            </>
                        );
                    })}

                    </tbody>
                </table>
            </div>
            <Pagination
                handlePageChange={callPictures}
                itemsPerPage={itemsPerPage.parPage}
                currentPage={itemsPerPage.current}
                totalItems={itemsPerPage.totalItems}
                startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                endIndex={
                    itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                        ? itemsPerPage.totalItems - 1
                        : itemsPerPage.current * itemsPerPage.parPage - 1
                }
            />
        </div>
    );
};

export default TableSubs;
