import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Input, Option, Select } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoriesAPI from "../../services/API/clients/CategoriesAPI";

export interface Category {
    enumValue: string
    frLabel: string
    enLabel: string
}

interface WrapperCardsProps {
    dataFiltered: { constEnumValue: string, enumValue: string, frLabel: string, enLabel: string, categories?: Category[] }[];
    currentEdit: number;
    setCurrentEdit: Function;
    dataEdit: { constEnumValue: string, enumValue: string; frLabel: string; enLabel: string };
    setDataEdit: Function;
    onSave: Function;
    onDelete: Function;
}

export default function WrapperCards({ dataFiltered, currentEdit, setCurrentEdit, dataEdit, setDataEdit, onSave, onDelete }: Readonly<WrapperCardsProps>) {
    const { t } = useTranslation();
    const [listCategories, setListCategories] = useState<{
        enumValue: string,
        frLabel: string,
        enLabel: string
    }[]>([])

    const fetchData = async () => {
        const response = await CategoriesAPI.getAll();
        if (response.status === 200) {
            setListCategories(response.body)
        }
    };

    useEffect(() => {
        fetchData();
    }, [])

    return (
        <div className="flex flex-wrap justify-between gap-4 mt-4">
            {dataFiltered.map((element, index) => (
                <Card placeholder="" key={element.enumValue} className="w-full md:w-80">
                    {currentEdit === index ? (
                        <div className="p-4 flex flex-col gap-2">
                            <Input
                                crossOrigin=""
                                type="text"
                                color="orange"
                                label={t("enumLabel")}
                                placeholder={t("enumValue")}
                                value={dataEdit.enumValue}
                                onChange={(e) => setDataEdit({ ...dataEdit, enumValue: e.target.value })}
                            />
                            <Input
                                crossOrigin=""
                                type="text"
                                color="orange"
                                label={t("frLabel")}
                                placeholder={t("frLabel")}
                                value={dataEdit.frLabel}
                                onChange={(e) => setDataEdit({ ...dataEdit, frLabel: e.target.value })}
                            />
                            <Input
                                crossOrigin=""
                                type="text"
                                color="orange"
                                label={t("enLabel")}
                                placeholder={t("enLabel")}
                                value={dataEdit.enLabel}
                                onChange={(e) => setDataEdit({ ...dataEdit, enLabel: e.target.value })}
                            />

                            <div className="flex justify-between gap-4 mt-2">
                                <Button placeholder="" variant="outlined" color="green" className="w-full" onClick={() => onSave()}>
                                    {t("save")}
                                </Button>
                                <Button placeholder="" variant="outlined" color="gray" className="w-full" onClick={() => setCurrentEdit(-1)}>
                                    {t("cancel")}
                                </Button>
                            </div>
                            <Button placeholder="" variant="outlined" color="red" onClick={() => onDelete(element.enumValue)}>
                                {t("delete")}
                            </Button>
                        </div>
                    ) : (
                        <div className="p-4 relative">
                            <FontAwesomeIcon
                                icon="pen"
                                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700 cursor-pointer transition-all"
                                onClick={() => {
                                    setCurrentEdit(index);
                                    setDataEdit(element);
                                }}
                            />
                            <h5 className="text-lg font-bold">{element.enumValue}</h5>
                            {element?.categories?.map((category) => (
                                <h6 className="text-base font-bold">{category.frLabel}</h6>
                            ))}
                            <p>{element.frLabel}</p>
                            <p>{element.enLabel}</p>
                        </div>
                    )}
                </Card>
            ))}
        </div>
    );
}
