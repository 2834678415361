import { Storage } from "../../storage";
import APIClient from "../APIClients";

const token = Storage.getToken();

export default class PortfolioAPI {
    public static async getPortfolio(id: string) {
        return APIClient.ezoom.get(`/public/portfolios/${id}`);
    }

    public static async editPortfolio(id: string, body: {}) {
        return APIClient.ezoom.put(`/portfolios/${id}`, body);
    }

    public static async getServices(id: string) {
        return APIClient.ezoom.get(`/public/services?draft=true&portfolio=${id}`);
    }

    public static async getServicesPagination(id: string, page: number) {
        return APIClient.ezoom.get(`/public/services?draft=true&portfolio=${id}&page=${page}`);
    }

    public static async putService(id: string, body: {}) {
        return APIClient.ezoom.put(`/services/${id}`, body)
    }

    public static async getTestimonials(id: string) {
        return APIClient.ezoom.get(`/public/testimonials?portfolio=${id}`);
    }

    public static async getTestimonialsPagination(id: string, page: number) {
        return APIClient.ezoom.get(`/public/testimonials?portfolio=${id}&page=${page}`);
    }

    public static async putTestimonial(id: string, body: {}) {
        return APIClient.ezoom.put(`/testimonials/${id}`, body)
    }
}
