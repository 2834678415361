export const Headers = [
    {
        id: "subject",
        col: "subject",
    },
    {
        id: "sender",
        col: "firstname",
    },
    {
        id: "senderMail",
        col: "lastname",
    }
];

export const HeadersPortfolio = [
    {
        id: "reason",
        col: "reason",
    },
    {
        id: "portfolio",
        col: "resource",
    },
    {
        id: "date",
        col: "createdAt",
    }
];

export const HeadersGalleries = [
    {
        id: "reason",
        col: "reason",
    },
    {
        id: "gallery",
        col: "resource",
    },
    {
        id: "date",
        col: "createdAt",
    }
];

export const HeadersPicture = [
    {
        id: "reason",
        col: "reason",
    },
    {
        id: "ID",
        col: "resource[id]",
    },
    {
        id: "picture",
        col: "resource[minPath]",
    },
    {
        id: "date",
        col: "createdAt",
    }
];